
import React from 'react';

import { Box, Button, Typography, LinearProgress } from '@mui/material';
import { QRCodeSVG } from 'qrcode.react';
import { CheckMobile } from './CheckMobile.js'
import logoFile from "./assets/images/tropilex-logo-relax-with-a-good-feeling.png"
import scanFile from "./assets/images/scan-to-see.png"

const OverLay = () => {

    const isMobile = CheckMobile()
    return (
        <React.Fragment >
            <Box
                sx={{
                    position: "absolute",
                    height: "20px",
                    top: "0",
                    width: "100%",
                    backgroundColor: "#14c7b9"
                }}
            ></Box>


            <Box component="img" sx={{
                top: { xs: "35px", md: "50px" },
                width: "150px",
                left: "calc(50% - 75px)",
                position: "absolute",
            }} src={logoFile}></Box>

            {!isMobile &&
                <Box sx={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    margin: "40px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "row",
                    alignContent: "center",
                    gap: "15px"
                }}>
                    <QRCodeSVG size="100" value={window.location.href} />
                    <Box component="img" sx={{
                        height: "100px",
                    }} src={scanFile}></Box>
                </Box>
            }

            <Box
                sx={{
                    position: "absolute",
                    height: "20px",
                    bottom: "0",
                    width: "100%",
                    backgroundColor: "#14c7b9"
                }}
            ></Box>
        </React.Fragment>
    );
};

export default OverLay;