import React from 'react';

import App from './components/App';
import HammockAPP from './components/HammockAPP';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import NotFound from "./404";

function RouterDom() {
  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route path="/:seriesID/:colorID/:standID" element={<HammockAPP />}></Route>
        <Route path="/:seriesID/:colorID" element={<HammockAPP />}></Route>
        <Route path="/:modelID" element={<App />}></Route>
        <Route path="/" element={<NotFound />}></Route>
        <Route path="*" element={<NotFound />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default RouterDom;

