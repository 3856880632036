import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import RouterDom from './RouterDom';
import '@google/model-viewer/dist/model-viewer';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <RouterDom />
);

