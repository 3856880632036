
import React, { useState, useEffect } from 'react';
import CustomHammock from './CustomHammock';
import OverLay from './OverLay';
import { Box, Button, CircularProgress } from '@mui/material';

import { useParams } from "react-router-dom";

function HammockAPP() {

    const [HammockColorData, setHammockColorData] = useState(null)
    const [urlData, setURLData] = useState()
    let { seriesID } = useParams();
    let { colorID } = useParams();
    let { standID } = useParams();
    // console.log({ seriesID, colorID, standID })

    // https://docs.google.com/spreadsheets/d/e/2PACX-1vTXxkMKhDS9JMXmUb8AGltPlkBmfiJdJBlIpLx7K1ywJeBMlZmBhzLnm1yMVxul4UUCpN8f2jlEJMD7/pub?output=csv

    const convertData = () => {
        console.log(HammockColorData)

        const newData = { ...HammockColorData }


        Object.keys(newData).forEach(key => {

            newData[key].colors.forEach(color => {
                color.colorImage = color.colorImage.split('.')[0];
                color.textureID = "hamock_matt_BaseColor_" + color.textureID + ".jpg"


            })

        })

        console.log({ newData })


        var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(newData));
        var dlAnchorElem = document.createElement("a")
        dlAnchorElem.setAttribute("href", dataStr);
        dlAnchorElem.setAttribute("download", "scene.json");
        dlAnchorElem.click();

    }

    useEffect(() => {

        fetch(`../../../assets/data/HammockColorData.json`)
            .then(response => {
                return response.json();
            })
            .then(data => {
                console.log(data[seriesID].colors)
                setHammockColorData(data)
                setURLData({
                    series: seriesID,
                    color: colorID,
                    stand: standID || data[seriesID].stands[0],
                    texture: data[seriesID].colors.find(color => color?.textureID.includes(colorID))?.textureID
                })
            }).catch(err => {
                console.log(err);
            });
    }, [])


    return (
        <React.Fragment>
            {HammockColorData !== null && urlData ?
                <CustomHammock data={HammockColorData} urlData={urlData} setURLData={setURLData} />
                :
                <Box sx={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    top: "0",
                    left: "0",
                    backgroundColor: "#ffffff50",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}>

                    <CircularProgress sx={{
                        color: "#14c7b9",
                    }} />
                </Box>
            }

            {/* <Button
                onClick={convertData}
                sx={{
                    position: "absolute",
                    top: "200px"
                }}
            >convert</Button> */}

            <OverLay />
        </React.Fragment >
    );
}

export default HammockAPP;
