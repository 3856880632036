
import React from 'react';

const NotFound = () => {
    return (
<React.Fragment >
<h2>404: Page Not Found</h2>
</React.Fragment>
    );
};

export default NotFound;