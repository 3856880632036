
import React, { useState, useEffect, useRef } from 'react';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import { Box, Button, Typography, LinearProgress, CircularProgress } from '@mui/material';

let colorUrlData
let standUrlData
let textureName
function CustomHammock(props) {

    const baseUrl = "../../assets"

    const [loading, setLoading] = useState(0);
    const [loaded, setLoaded] = useState(false);
    const [seriesID, setSeriesID] = useState(props.urlData.series)
    const [currentColor, setCurrentColor] = useState(props.urlData.color)
    const [currentTexture, setCurrentTexture] = useState(props.urlData.texture)
    const [currentStand, setCurrentStand] = useState(props.urlData.stand)
    const [loadingTexture, setLoadingTexture] = useState(true)

    // console.log(props.urlData)
    // ["wood", "easy", "arc"]
    const standData = props.data[seriesID].stands
    // console.log(standData)
    // console.log(props.data)
    const colors = props.data[seriesID].colors
    const modelViewerRef = useRef()
    const setNewURL = (data) => {
        const origin = window.location.origin
        const nextURL = `${origin}/${seriesID}/${colorUrlData}/${standUrlData}`
        window.history.replaceState(null, null, nextURL);

    }

    const changeColorHandler = async (data) => {

        setLoadingTexture(true)
        const textureMap = await modelViewerRef.current.createTexture(`${baseUrl}/${seriesID}/textures/${data.textureID}`);
        modelViewerRef.current.model.materials[0].pbrMetallicRoughness["baseColorTexture"].setTexture(textureMap)
        setLoadingTexture(false)
        setCurrentColor(data.colorImage)
        colorUrlData = data.colorImage
        setNewURL()
    }

    window.onpopstate = function (e) {
        changeColorHandler({
            colorImage: null,
            textureID: currentTexture
        },)
    };

    const changeStandHandler = async (data) => {
        if (data !== currentStand) {
            setLoaded(false)
            setCurrentStand(data)
            standUrlData = data
            setNewURL()
        }
    }


    useEffect(() => {


        colorUrlData = props.urlData.color
        standUrlData = props.urlData.stand

        const modelViewer = modelViewerRef.current
        modelViewer.addEventListener("progress", (e) => {
            const presentage = Math.floor(e.detail.totalProgress * 100)
            setLoading(presentage)
        })
        modelViewer.addEventListener("load", () => {
            setLoadingTexture(false)
            changeColorHandler({
                colorImage: props.urlData.color,
                textureID: currentTexture
            },)

            setTimeout(() => {
                setLoaded(true)
            }, 1000);

        });
    }, [])
    return (
        <React.Fragment>

            <model-viewer
                style={{
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    top: "0",
                }}
                ref={modelViewerRef}
                id="model-viewer-demo"
                poster={`${baseUrl}/${seriesID}/${seriesID}.png`}
                class="ft-model-viewer"
                src={`${baseUrl}/${seriesID}/${seriesID}_${currentStand}.glb`}
                shadow-intensity="1"
                exposure="0.7"
                camera-orbit="45deg 75deg auto"
                max-camera-orbit="auto auto 12m"
                tone-mapping="neutral"
                camera-controls
                ar
                alt="A 3D model of a webar"
                ar-modes="webxr scene-viewer quick-look"
                autoplay
                loading="eager"
            >
                <Button slot="ar-button"
                    variant="outlined"
                    sx={{
                        display: !loaded && "none",
                        borderRadius: "200px",
                        position: "absolute",
                        top: "120px",
                        right: "20px",
                        gap: "10px",
                        color: "#14c7b9",
                        backgroundColor: "white",
                        borderColor: "#14c7b9",
                    }}
                >
                    <ViewInArIcon />
                </Button>
                <div slot="progress-bar"></div>

                {loaded &&
                    <Box
                        sx={{
                            display: 'flex',
                            position: "absolute",
                            bottom: "35px",
                            width: "100%",
                            gap: "10px",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                        }}>
                        <Box sx={{
                            display: 'flex',
                            width: "100%",
                            gap: "20px",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "row",
                        }}>
                            {standData?.length > 1 &&
                                standData.map((data, index) =>
                                    <Box
                                        sx={{
                                            width: { xs: "60px", md: "80px" },
                                            height: { xs: "60px", md: "80px" },
                                            borderRadius: "10px",
                                            cursor: "pointer",
                                            border: data === currentStand ? "3px solid #14c7b9" : "3px solid #14c7b95c",
                                            backgroundColor: "#ffffffb8",
                                            backgroundImage: data === "none" ? `url("${baseUrl}/${seriesID}/${seriesID}_${data}.png")` : `url("${baseUrl}/stand-images/${data}.png")`,
                                            backgroundPosition: "center",
                                            backgroundSize: " 120%",
                                        }}
                                        onClick={(e) => { changeStandHandler(data) }}
                                        key={index}></Box>
                                )
                            }
                        </Box>

                        <Box sx={{
                            display: 'flex',
                            width: "100%",
                            gap: "10px",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "row",
                        }}>
                            {
                                colors.map((data, index) =>
                                    <Box
                                        sx={{
                                            width: { xs: "25px", md: "40px" },
                                            height: { xs: "25px", md: "40px" },
                                            borderRadius: "8px",
                                            cursor: "pointer",
                                            border: data.colorImage === currentColor ? "3px solid #14c7b9" : "3px solid #14c7b95c",
                                            backgroundColor: "#ffffffb8",
                                            backgroundImage: `url("${baseUrl}/${seriesID}/colors/${data.colorImage}.png")`,
                                            backgroundPosition: "center",
                                            backgroundSize: " 140%",
                                        }}
                                        className="slect-colors"
                                        onClick={(e) => { changeColorHandler(data) }}
                                        key={index}></Box>
                                )
                            }
                        </Box>

                        {/* <Typography sx={{
              color: "#14c7b9",
            }}>{colorName}</Typography> */}
                    </Box>
                }
                {
                    !loaded &&
                    <Box sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        position: "absolute",
                        bottom: "50px",
                        width: "100%",
                        gap: "10px",
                        zIndex: "200",
                    }}>
                        <Typography sx={{
                            color: "#14c7b9",
                        }}>{`${loading}%`}</Typography>
                        <Typography sx={{
                            color: "#14c7b9",
                        }}>LOADING MODEL...</Typography>
                        <LinearProgress
                            sx={{
                                width: "70%",
                                height: '10px',
                                borderRadius: '22px',
                                backgroundColor: "#14c7b980",
                                '& .MuiLinearProgress-bar1Determinate': {
                                    backgroundColor: '#14c7b9',
                                    borderRadius: '22px',
                                }
                            }}
                            variant="determinate" value={loading} />
                    </Box>
                }
            </model-viewer>
            {loadingTexture &&
                <Box sx={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    top: "0",
                    left: "0",
                    backgroundColor: "#ffffff50",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}>

                    <CircularProgress sx={{
                        color: "#14c7b9",
                    }} />
                </Box>
            }


        </React.Fragment >
    );
}

export default CustomHammock;
