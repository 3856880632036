
import React, { useState, useEffect } from 'react';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import { Box, Button, Typography, LinearProgress } from '@mui/material';
import { useParams } from "react-router-dom";
import OverLay from './OverLay';

function App() {
  let { modelID } = useParams();
  const [loading, setLoading] = useState(0);
  const [loaded, setLoaded] = useState(false);
  // hammock_easy_340



  console.log(modelID)

  useEffect(() => {
    const modelViewer = document.querySelector('#model-viewer-demo');
    modelViewer.addEventListener("progress", (e) => {
      const presentage = Math.floor(e.detail.totalProgress * 100)
      setLoading(presentage)
    })
    modelViewer.addEventListener("load", () => {
      setTimeout(function () {
        setLoaded(true)
      }, 1000);
    });
  }, [])
  return (
    <React.Fragment>

      <model-viewer
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          top: "0",
        }}
        id="model-viewer-demo"
        poster={`assets/stands/${modelID}.png`}
        class="ft-model-viewer"
        src={`assets/stands/${modelID}.glb`}
        // src = "assets/hammock/hammock.glb"
        shadow-intensity="1"
        camera-orbit="44.5deg 73.67deg auto"
        camera-controls
        ar
        alt="A 3D model of a webar"
        ar-modes="webxr scene-viewer quick-look"
        autoplay
        loading="eager"
      >
        <Button slot="ar-button"
          variant="outlined"
          sx={{
            display: !loaded && "none",
            borderRadius: "200px",
            position: "absolute",
            bottom: "30px",
            transform: "translate(-50%, -50%)",
            left: "50%",
            gap: "10px",
            color: "#14c7b9",
            backgroundColor: "white",
            borderColor: "#14c7b9",
          }}
        >
          <ViewInArIcon />
          View in AR</Button>
        <div slot="progress-bar"></div>

        {
          !loaded &&
          <Box sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            position: "absolute",
            bottom: "50px",
            width: "100%",
            gap: "10px"
          }}>
            <Typography sx={{
              color: "#14c7b9",
            }}>{`${loading}%`}</Typography>
            <Typography sx={{
              color: "#14c7b9",
            }}>LOADING MODEL...</Typography>
            <LinearProgress
              sx={{
                width: "70%",
                height: '10px',
                borderRadius: '22px',
                backgroundColor: "#14c7b980",
                '& .MuiLinearProgress-bar1Determinate': {
                  backgroundColor: '#14c7b9',
                  borderRadius: '22px',
                }
              }}
              variant="determinate" value={loading} />
          </Box>
        }

      </model-viewer>

      <OverLay />

    </React.Fragment >
  );
}

export default App;
